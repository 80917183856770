import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../styles/Header.module.css';
import logo from '../components/Logo médaillon 2.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fermer le menu lorsque la route change sur mobile et tablette
  useEffect(() => {
    if (window.innerWidth <= 767) {
      setIsMenuOpen(false);
    }
  }, [location]);

  return (
    <>
      <header className={`${styles['main-header']} ${isMenuOpen ? styles.show : ''}`}>
        <div className={styles['header-container']}>
          <nav className={styles.nav}>
            <ul className={styles['nav_menu_list']}>
              <li className={`${styles['nav_list']} ${styles['logo-item']}`} style={{ '--animation-order': 0 }}>
                <Link to="/">
                  <img src={logo} alt="ISA KAM Décor et Design" className={styles['nav-logo-img']} />
                </Link>
              </li>
              <li className={styles.nav_list} style={{ '--animation-order': 1 }}>
                <Link to="/" className={styles['nav-link']}>Accueil</Link>
              </li>
              <li className={styles.nav_list} style={{ '--animation-order': 2 }}>
                <Link to="/location" className={styles['nav-link']}>Locations</Link>
              </li>
              <li className={styles.nav_list} style={{ '--animation-order': 3 }}>
                <Link to="/services" className={styles['nav-link']}>Nos prestations Design / Décor</Link>
              </li>
              <li className={styles.nav_list} style={{ '--animation-order': 4 }}>
                <Link to="/contact" className={styles['nav-link']}>Contacts</Link>
              </li>
              <li className={styles.nav_list} style={{ '--animation-order': 5 }}>
                <Link to="/faq" className={styles['nav-link']}>FAQ</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <div className={styles['header-toggle']} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
};

export default Header;