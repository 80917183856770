import React from 'react';
import instagramLogo from '../assets/instagram_logo.png';
import facebookLogo from '../assets/facebook_logo.png';
import styles from '../styles/SocialMediaIcons.module.css'; // Importer le fichier CSS renommé

const SocialMediaIcons = () => {
  return (
    <div className={styles['social-media-icons']}>
      <a href="https://www.instagram.com/isakam.decor/" target="_blank" rel="noopener noreferrer">
        <img src={instagramLogo} alt="Instagram" className={styles['social-icon']} />
      </a>
      <a href="https://www.facebook.com/isakam.decor" target="_blank" rel="noopener noreferrer">
        <img src={facebookLogo} alt="Facebook" className={styles['social-icon']} />
      </a>
    </div>
  );
};

export default SocialMediaIcons;