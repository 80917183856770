import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Layout from './components/Layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomePage = lazy(() => import('./pages/HomePage'));
const ServicesPage = lazy(() => import('./pages/ServicesPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const Location = lazy(() => import('./pages/Location'));
const FAQPage = lazy(() => import('./pages/FAQPage'));
const CGVPage = lazy(() => import('./pages/CGV')); // Nouvelle importation

const pageVariants = {
  initial: {
    y: '-100vh',
    opacity: 0,
  },
  in: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.23,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
  out: {
    y: '100vh',
    opacity: 0,
    transition: {
      duration: 1.23,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<motion.div initial="initial" animate="in" exit="out" variants={pageVariants}><HomePage /></motion.div>} />
        <Route path="/services" element={<motion.div initial="initial" animate="in" exit="out" variants={pageVariants}><ServicesPage /></motion.div>} />
        <Route path="/contact" element={<motion.div initial="initial" animate="in" exit="out" variants={pageVariants}><ContactPage /></motion.div>} />
        <Route path="/location" element={<motion.div initial="initial" animate="in" exit="out" variants={pageVariants}><Location /></motion.div>} />
        <Route path="/faq" element={<motion.div initial="initial" animate="in" exit="out" variants={pageVariants}><FAQPage /></motion.div>} />
        <Route path="/cgv" element={<motion.div initial="initial" animate="in" exit="out" variants={pageVariants}><CGVPage /></motion.div>} /> {/* Nouvelle route */}
      </Routes>
    </AnimatePresence>
  );
};

function App() {
  const [showFixedHeader, setShowFixedHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      setShowFixedHeader(scrollPosition > window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <Suspense fallback={<div>Chargement...</div>}>
        <Layout>
          <AnimatedRoutes />
        </Layout>
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default App;
