import React, { lazy, Suspense } from 'react';
import Header from './Header';
import ScrollToTopButton from './ScrollToTopButton';
import SocialMediaIcons from './SocialMediaIcons';
import styles from '../styles/Layout.module.css';

const LazyFooter = lazy(() => import('./Footer'));

const Layout = ({ children }) => {
  return (
    <div className={styles.layout}>
      <Header />
      <main>{children}</main>
      <Suspense fallback={<div>Chargement du footer...</div>}>
        <LazyFooter />
      </Suspense>
      <ScrollToTopButton />
      <SocialMediaIcons />
    </div>
  );
};

export default Layout;
